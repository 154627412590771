import React, { useState, useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/ProductDetailsStyles.css";
import toast from "react-hot-toast";
import { useCart } from "../context/cart";
import { backendUrl } from "../consants";

const ProductDetails = () => {
  const params = useParams();
  const [cart, setCart] = useCart([]);
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);

  //initalp details
  useEffect(() => {
    if (params?.slug) getProduct();
  }, [params?.slug]);
  //getProduct
  const getProduct = async () => {
    try {
      // console.log("params", params);
      const { data } = await axios.get(
        `/api/v1/product/get-product/${params.slug}`
      );
      setProduct(data?.product);
      getSimilarProduct(data?.product._id, data?.product.category._id);
    } catch (error) {
      console.log(error);
    }
  };
  //get similar product
  const getSimilarProduct = async (pid, cid) => {
    try {
      const { data } = await axios.get(
        `/api/v1/product/related-product/${pid}/${cid}`
      );
      setRelatedProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };

  function arrayBufferToBase64(arrayBuffer) {
    let binary = "";
    const bytes = new Uint8Array(arrayBuffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  return (
    <Layout>
<div className="row container product-details py-4">
  <div className="col-md-6 d-flex justify-content-center align-items-center">
    <img
      src={`${backendUrl}api/v1/product/product-photo/${product._id}`}
      className="rounded border border-secondary shadow-sm"
      alt={product.name}
      style={{ height: '350px', width: 'auto', objectFit: 'cover' }}
    />
  </div>
  <div className="col-md-6 product-details-info d-flex flex-column justify-content-center">
    <h2 className="text-center mb-4">Product Details</h2>
    <hr />
    <h5><strong>Name:</strong> {product.name}</h5>
    <p><strong>Description:</strong> {product.description}</p>
    <h5><strong>Price:</strong> 
      {product?.price?.toLocaleString("en-US", {
        style: "currency",
        currency: "INR",
      })}
    </h5>
    <h6><strong>Category:</strong> {product?.category?.name}</h6>
    <div className="mt-4">
      <button
        className="btn btn-dark btn-lg px-4"
        onClick={() => {
          const newCart = cart.length > 0 ? [...cart, product] : [product];
          setCart(newCart);
          localStorage.setItem("cart", JSON.stringify(newCart));
          toast.success("Item Added to cart");
        }}
      >
        ADD TO CART
      </button>
    </div>
  </div>
</div>

      <hr />
      <div className="row container similar-products">
        <h4>Similar Products ➡️</h4>
        {relatedProducts.length < 1 && (
          <p className="text-center">No Similar Products found</p>
        )}
        <div className="d-flex flex-wrap">
          {relatedProducts?.map((p) => (
            <div className="col-md-3 mb-4" key={p._id}>
              <div
                className="card h-100 border border-secondary rounded"
                onClick={() => navigate(`/product/${p.slug}`)}
              >
                <img
                  src={`${backendUrl}api/v1/product/product-photo/${p._id}`}
                  className="card-img-top border border-secondary"
                  alt={p.name}
                  style={{ objectFit: "cover", height: "200px" }}
                />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">{p.name}</h5>
                  <h6 className="card-text">
                    {p.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                    })}
                  </h6>
                  <p className="card-text flex-grow-1">
                    {p.description.substring(0, 60)}...
                  </p>
                </div>
                <div className="card-footer bg-transparent border-top">
                  <button
                    className="btn btn-info border border-primary me-2"
                    style={{ backgroundColor: "#17a2b8", color: "#fff" }} // Custom color for More Details button
                    onClick={() => navigate(`/product/${p.slug}`)}
                  >
                    More Details
                  </button>
                  {/* Uncomment to add Add to Cart functionality */}
                  {/* <button
              className="btn btn-dark border border-warning"
              style={{ backgroundColor: '#343a40', color: '#fff' }} // Custom color for Add to Cart button
              onClick={() => {
                const newCart = cart.length > 0 ? [...cart, p] : [p];
                setCart(newCart);
                localStorage.setItem("cart", JSON.stringify(newCart));
                toast.success("Item Added to cart");
              }}
            >
              Add to Cart
            </button> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ProductDetails;
