import React from "react";
import Layout from "./../components/Layout/Layout";
import { BiMailSend, BiPhoneCall } from "react-icons/bi";

const Contact = () => {
  return (
    <Layout title={"Contact us"}>
      <div className="flex align-items-center justify-content-center">
        <div className="row contactus d-flex align-items-center ">
          {/* Image Column */}
          <div className="col-12 col-md-6 mb-3 mb-md-0 d-none d-md-block">
            <img
              src="/images/contactus.jpeg"
              alt="contactus"
              className="img-fluid"
              style={{ width: "100%", maxHeight: "400px", objectFit: "cover" }}
            />
          </div>

          {/* Contact Info Column */}
          <div className="col-12 col-md-6 d-flex flex-column align-items-center justify-content-center h-fit">
            <h1 className="bg-dark p-2 text-white text-center w-100">
              CONTACT US
            </h1>
            <p className="text-center mt-3">
              Any queries or info about products? Feel free to call us anytime.
              We are available 24/7.
            </p>
            <div className="text-center mt-3">
              <p>
                <BiMailSend />: elegantedge62@gmail.com
              </p>
              <p>
                <BiPhoneCall />: +918107289227
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
