import React from 'react';
import Layout from '../../components/Layout/Layout';

function ShippingPolicy() {
  return (
    <Layout title={"Shipping Policy | ElegantEdge"}>
      <div className="container mt-5">
        <div className="">
          <header className="bg-primary text-white text-center p-4">
            <h1>Shipping Policy</h1>
          </header>
          <main className="container my-5">
            <p className="lead">
              Welcome to ElegantEdge! This Shipping Policy outlines the terms and conditions for the shipment and pickup of orders placed on our website. By using our services, you agree to comply with and be bound by this Shipping Policy.
            </p>
            <section className="mb-5">
              <h2>Order Processing Time</h2>
              <p>
                <strong>Maximum Processing Time:</strong> We strive to process all orders within 1 business day of receiving payment. Orders placed on weekends or holidays will be processed on the next business day.
              </p>
            </section>
            <section className="mb-5">
              <h2>Shipping Options</h2>
              <p>Customers have the following shipping options:</p>
              <ul>
                <li className="mb-3">
                  <strong>Delivery:</strong> We offer delivery with a minimum shipping time of 2 days and a maximum shipping time of 5 days. Delivery charges may apply and will be calculated at checkout. Please note that shipping times may vary depending on your location and any unforeseen circumstances such as weather conditions or carrier delays.
                </li>
                <li className="mb-3">
                  <strong>Pickup:</strong> Customers can also choose to pick up their orders from our designated pickup location: <strong>10H6 PWD, Munda para, Rakabganj, Agra,UttarPradesh 282003</strong>. Orders must be picked up within 5 days of receiving the pickup notification. Pickup hours are from 9 am to 7 pm. Please bring a valid ID and your order confirmation email for pickup.
                </li>
              </ul>
            </section>
            <section className="mb-5">
              <h2>Shipping Rates</h2>
              <p>
                Shipping rates are calculated based on the weight and destination of the package. You can view the shipping charges for your order during the checkout process. Please note that additional fees may apply for expedited shipping or international deliveries.
              </p>
            </section>
            <section className="mb-5">
              <h2>Tracking Your Order</h2>
              <p>
                Once your order has been shipped, you will receive a tracking number via email. You can use this tracking number to monitor the status of your delivery. If you have any questions about the status of your order, please contact our customer support team for assistance.
              </p>
            </section>
            <section>
              <h2>Contact Us</h2>
              <p>
                If you have any questions or concerns regarding our Shipping Policy, please contact us at <a href="mailto:elegantedge62@gmail.com">elegantedge62@gmail.com</a>. Our customer support team is available to assist you during our business hours.
              </p>
              <p>
                Thank you for shopping with ElegantEdge! We appreciate your business and strive to provide you with a seamless shopping experience from start to finish.
              </p>
            </section>
          </main>
        </div>
      </div>
    </Layout>
  );
}

export default ShippingPolicy;
