import React from "react";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
const Footer = () => {
  return (
    <footer className="f">
      <div className="container text-center">
        <h5 className="mb-3">All Rights Reserved &copy; ElegantEdge</h5>
        <p>
          <Link to="/about" className="f-link mx-2">
            About
          </Link>
          |
          <Link to="/contact" className="f-link mx-2">
            Contact
          </Link>
          |
          <Link to="/privacy-policy" className="f-link mx-2">
            Privacy Policy
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
