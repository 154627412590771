import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import { Prices } from "../components/Prices";
import { useCart } from "../context/cart";
import axios from "axios";
import toast from "react-hot-toast";
import Layout from "./../components/Layout/Layout";
import { AiOutlineReload } from "react-icons/ai";
import "../styles/Homepage.css";
import { backendUrl } from "../consants.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const HomePage = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useCart([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [checked, setChecked] = useState([]);
  const [radio, setRadio] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  // Get all categories
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get("/api/v1/category/get-category");
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
    getTotal();
  }, []);

  // Get products
  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/v1/product/product-list/${page}`);
      setLoading(false);
      setProducts(data.products);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // Get total product count
  const getTotal = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/product-count");
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (page === 1) return;
    loadMore();
  }, [page]);

  // Load more products
  const loadMore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/v1/product/product-list/${page}`);
      setLoading(false);
      setProducts([...products, ...data?.products]);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Filter by category
  const handleFilter = (value, id) => {
    let all = [...checked];
    if (value) {
      all.push(id);
    } else {
      all = all.filter((c) => c !== id);
    }
    setChecked(all);
  };

  useEffect(() => {
    if (!checked.length || !radio.length) getAllProducts();
  }, [checked.length, radio.length]);

  useEffect(() => {
    if (checked.length || radio.length) filterProduct();
  }, [checked, radio]);

  // Get filtered products
  const filterProduct = async () => {
    try {
      const { data } = await axios.post("/api/v1/product/product-filters", {
        checked,
        radio,
      });
      setProducts(data?.products);
      // console.log(products.length)
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Layout title={"All Products - Best Offers"} mT="0vh">
      <div className="fullscreen-container">
  <img src="/images/banner.jpeg" alt="Shopping" className="card-img-top border border-secondary" />
  <div className="scroll-indicator">
    <img src="/images/scroll-down.png" alt="Scroll Down" />
  </div>
</div>

      {/* Filters Dropdown */}
      <div className="container mt-4">
        <button className="btn btn-info" onClick={() => setShowFilters(true)}>
          Show Filters
        </button>
      </div>

      {/* Filters Modal */}
      <Modal show={showFilters} onHide={() => setShowFilters(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Filter By Category</h4>
          {categories?.map((c) => (
            <div key={c._id}>
              <input
                type="checkbox"
                onChange={(e) => handleFilter(e.target.checked, c._id)}
              />
              {c.name}
            </div>
          ))}

          <h4 className="mt-4">Filter By Price</h4>
          {Prices?.map((p) => (
            <div key={p._id}>
              <input
                type="radio"
                name="price"
                value={p.array}
                checked={radio === p.array}
                onChange={() => setRadio(p.array)}
              />
              {p.name}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => window.location.reload()}>
            Reset Filters
          </button>
          <button className="btn btn-primary" onClick={() => setShowFilters(false)}>
            Apply Filters
          </button>
        </Modal.Footer>
      </Modal>

      {/* Product Cards */}
<div className="container mt-5">
  <div className="row">
    {products?.map((p) => (
      <div className="col-md-3 mb-4" key={p._id}>
        <div className="card h-100 border border-secondary rounded">
          <img
            src={`${backendUrl}api/v1/product/product-photo/${p._id}`}
            className="card-img-top border border-secondary"
            alt={p.name}
            style={{ objectFit: 'cover', height: '200px' }}
          />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title">{p.name}</h5>
            <h6 className="card-text">
              {p.price.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })}
            </h6>
            <p className="card-text flex-grow-1">
              {p.description.substring(0, 60)}...
            </p>
          </div>
          <div className="card-footer bg-transparent border-top">
            <button
              className="btn btn-info border border-primary me-2"
              style={{ backgroundColor: '#17a2b8', color: '#fff' }} // Custom color for More Details button
              onClick={() => navigate(`/product/${p.slug}`)}
            >
              More Details
            </button>
            <button
              className="btn btn-dark border border-warning"
              style={{ backgroundColor: '#343a40', color: '#fff' }} // Custom color for Add to Cart button
              onClick={() => {
                const newCart = cart.length > 0 ? [...cart, p] : [p];
                setCart(newCart);
                localStorage.setItem("cart", JSON.stringify(newCart));
                toast.success("Item Added to cart");
              }}
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    ))}
  </div>

  {/* Load More Button */}
  {(products.length || loading) ? (
    <div className="text-center mt-4">
      {products && products.length < total && (
        <button
          className="btn btn-primary border border-dark"
          onClick={(e) => {
            e.preventDefault();
            setPage(page + 1);
          }}
        >
          {loading ? "Loading ..." : "Load More"} <AiOutlineReload />
        </button>
      )}
    </div>
  ) : (
    <div className="text-center mt-4">
      <h3>No Products Found</h3>
    </div>
  )}
</div>


    </Layout>
  );
};

export default HomePage;
