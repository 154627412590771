import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/CategoryProductStyles.css";
import axios from "axios";
import { backendUrl } from "../consants";

const CategoryProduct = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    if (params?.slug) getProductsByCat();
  }, [params?.slug]);

  const getProductsByCat = async () => {
    try {
      const { data } = await axios.get(
        `/api/v1/product/product-category/${params.slug}`
      );
      setProducts(data?.products);
      setCategory(data?.category);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="container mt-3 category">
        <h4 className="text-center">Category - {category?.name}</h4>
        <h6 className="text-center">{products?.length} result found</h6>
        <div className="d-flex flex-wrap">
          {products?.map((p) => (
            <div className="col-md-3 mb-4" key={p._id}>
              <div
                className="card h-100 border border-secondary rounded"
                onClick={() => navigate(`/product/${p.slug}`)}
              >
                <img
                  src={`${backendUrl}api/v1/product/product-photo/${p._id}`}
                  className="card-img-top border border-secondary"
                  alt={p.name}
                  style={{ objectFit: "cover", height: "200px" }}
                />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">{p.name}</h5>
                  <h6 className="card-text">
                    {p.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                    })}
                  </h6>
                  <p className="card-text flex-grow-1">
                    {p.description.substring(0, 60)}...
                  </p>
                </div>
                <div className="card-footer bg-transparent border-top">
                  <button
                    className="btn btn-info border border-primary me-2"
                    style={{ backgroundColor: "#17a2b8", color: "#fff" }}
                    onClick={() => navigate(`/product/${p.slug}`)}
                  >
                    More Details
                  </button>
                  {/* Uncomment to add Add to Cart functionality */}
                  {/* <button
                    className="btn btn-dark border border-warning"
                    style={{ backgroundColor: '#343a40', color: '#fff' }}
                    onClick={() => {
                      const newCart = cart.length > 0 ? [...cart, p] : [p];
                      setCart(newCart);
                      localStorage.setItem("cart", JSON.stringify(newCart));
                      toast.success("Item Added to cart");
                    }}
                  >
                    Add to Cart
                  </button> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default CategoryProduct;
