import React from "react";
import Layout from "../../components/Layout/Layout";

const PrivacyPolicy = () => {
  return (
    <Layout title={"Privacy Policy | ElegantEdge"} mT="10vh">
      <div className="container mt-5">
        <div className="">
          <div className="bg-primary text-white text-center p-4">
            <h2>Privacy Policy</h2>
          </div>
          <div className="card-body">
            <h4>1. Information We Collect:</h4>
            <p>
              We collect various types of information when you interact with our clothing website, including but not limited to:
            </p>
            <ul>
              <li>
                Personal Information: Name, shipping address, phone number, email address, and other contact details.
              </li>
              <li>
                Billing Information: Credit card or other payment details for processing your orders.
              </li>
              <li>
                Account Information: Username, password, and preferences for your account.
              </li>
              <li>
                Purchase Information: Details about the clothing items you browse, add to your cart, or purchase, along with your purchase history.
              </li>
              <li>
                Device and Usage Information: Information about the device you use to access our website, including IP address, browser type, and operating system.
              </li>
              <li>
                Communication Information: Records of your interactions with our customer support or through other means of communication.
              </li>
              <li>
                Cookies and Similar Technologies: Information collected through cookies and similar technologies to enhance your shopping experience.
              </li>
            </ul>
          </div>

          <div className="card-body">
            <h4>2. How We Use Your Information:</h4>
            <p>We use your information for various purposes, including:</p>
            <ul>
              <li>
                Processing Orders: To process and fulfill your orders for clothing, including payment processing and shipping.
              </li>
              <li>
                Account Management: To manage your account and provide order status updates.
              </li>
              <li>
                Personalization: To tailor your shopping experience and offer personalized clothing recommendations based on your preferences.
              </li>
              <li>
                Marketing and Promotions: To inform you about special offers, promotions, and new clothing arrivals, provided you've opted in to receive such communications.
              </li>
              <li>
                Analytics: To analyze user behavior to improve our website and enhance our clothing offerings.
              </li>
              <li>
                Legal and Security: To comply with legal obligations and protect the security and integrity of our website.
              </li>
            </ul>
          </div>

          <div className="card-body">
            <h4>3. How We Share Your Information:</h4>
            <p>
              We may share your information with third parties under the following circumstances:
            </p>
            <ul>
              <li>
                Service Providers: We may share your data with third-party service providers for payment processing, shipping, and marketing purposes.
              </li>
              <li>
                Legal Compliance: We may disclose your information when required by law or to protect our rights, safety, and property.
              </li>
            </ul>
          </div>

          <div className="card-body">
            <h4>4. Your Choices and Rights:</h4>
            <p>
              You have rights regarding your personal information, including the right to access, correct, or delete your data. You can also opt out of marketing communications at any time. Please contact us at [contact email] to exercise these rights or for any privacy-related inquiries.
            </p>
          </div>

          <div className="card-body">
            <h4>5. Security:</h4>
            <p>
              We prioritize security and use reasonable administrative, technical, and physical safeguards to protect your information from unauthorized access, disclosure, alteration, or destruction.
            </p>
          </div>

          <div className="card-body">
            <h4>6. Cookies:</h4>
            <p>
              We use cookies and similar technologies to enhance your shopping experience and understand user preferences. You can manage your cookie preferences through your browser settings.
            </p>
          </div>

          <div className="card-body">
            <h4>7. Children's Privacy:</h4>
            <p>
              Our website and services are not intended for children under 13 years of age. We do not knowingly collect information from children under 13. If you believe we have inadvertently collected such information, please contact us to have it removed.
            </p>
          </div>

          <div className="card-body">
            <h4>8. Changes to this Policy:</h4>
            <p>
              We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will provide notice of any material changes on our website.
            </p>
          </div>

          <div className="card-body">
            <h4>9. Contact Us:</h4>
            <p>
              If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at:
            </p>
            <p>
              <a href="mailto:elegantedge62@gmail.com">elegantedge62@gmail.com</a> | <a href="tel:+918107289227">+91-8107289227</a>
            </p>
            <p>
              Thank you for trusting ElegantEdge with your personal information. We are committed to maintaining your privacy and providing you with a secure and enjoyable shopping experience.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
