import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import SearchInput from "../Form/SearchInput";
import useCategory from "../../hooks/useCategory";
import { useCart } from "../../context/cart";
import { Badge } from "antd";
import { IoMdMenu } from "react-icons/io";

import "../../styles/header.css";

const Header = () => {
  const [auth, setAuth] = useAuth();
  const [cart] = useCart();
  const categories = useCategory();
  
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
  };
  
  return (
    <nav className="navbar navbar-expand-lg navbar-dark shadow fixed-top header-blur">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand fw-bold">
        <div style={{display:"flex" , alignItems:"center", gap:"2px"}}>
        <img src="/images/logo.png" alt="logo" style={{width: "50px"}} />
        <i>EE</i>
        </div>
        </Link>
        <button
          className="navbar-toggler "
          style={{zIndex: "9999", color: "white"}}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <IoMdMenu size={30} />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <SearchInput />
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink to="/" className="nav-link fw-bold text-white">
                Home
              </NavLink>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle text-white"
                to={"/categories"}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Categories
              </Link>
              <ul className="dropdown-menu bg-light">
                <li>
                  <Link className="dropdown-item" to={"/categories"}>
                    All Categories
                  </Link>
                </li>
                {categories?.map((c) => (
                  <li key={c._id}>
                    <Link className="dropdown-item" to={`/category/${c.slug}`}>
                      {c.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle text-white"
                to={"/policies"}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Policies
              </Link>
              <ul className="dropdown-menu bg-light">
                <li>
                  <Link className="dropdown-item" to={"/about"}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={"/privacy-policy"}>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={"/return-policy"}>
                    Return Policy
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={"/shipping-policy"}>
                    Shipping Policy
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={"/terms-of-use"}>
                    Terms of Use
                  </Link>
                </li>
              </ul>
            </li>

            {!auth?.user ? (
              <>
                <li className="nav-item">
                  <NavLink to="/register" className="nav-link fw-bold text-white">
                    Register
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/login" className="nav-link fw-bold text-white">
                    Login
                  </NavLink>
                </li>
              </>
            ) : (
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle text-white"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {auth?.user?.name}
                </NavLink>
                <ul className="dropdown-menu bg-light">
                  <li>
                    <NavLink
                      to={`/dashboard/${auth?.user?.role === 1 ? "admin" : "user"}`}
                      className="dropdown-item"
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={handleLogout} to="/login" className="dropdown-item">
                      Logout
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
            <li className="nav-item">
              <NavLink to="/cart" className="nav-link fw-bold text-white">
                <Badge count={cart?.length} showZero offset={[10, -5]}>
                  Cart
                </Badge>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
