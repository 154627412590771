import React from 'react';
import Layout from '../../components/Layout/Layout';

const TermsOfUse = () => {
  return (
    <Layout title={"Terms of Use | ElegantEdge"}>
      <div className="container mt-5">
        <div className="">
          <div className="card-header text-center bg-primary text-white">
            <h2>Terms of Use</h2>
            <p className="mt-2"><strong>Effective Date:</strong> 20 January 2024</p>
          </div>
          <div className="card-body">
            <p>
              Welcome to ElegantEdge! These Terms of Use outline the terms and conditions governing your use of our website and services related to clothing and fashion. By accessing or using ElegantEdge, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our services.
            </p>

            <h4>Website Content</h4>
            <ol>
              <li><strong>Intellectual Property:</strong> All content on the Website, including text, images, graphics, logos, and trademarks related to clothing and fashion, is protected by intellectual property laws. You may not use, reproduce, distribute, or modify this content without our written consent.</li>
              <li><strong>Accuracy:</strong> We strive to provide accurate and up-to-date information about our clothing products. However, we do not guarantee the accuracy, completeness, or timeliness of the content. You use the information on the Website at your own risk.</li>
            </ol>

            <h4>User Conduct</h4>
            <p>When using our services, you agree to:</p>
            <ol>
              <li><strong>Comply with Laws:</strong> Abide by all applicable local, state, and federal laws and regulations related to online shopping and consumer rights.</li>
              <li><strong>Respect Others:</strong> Refrain from engaging in any conduct that may harm, harass, or violate the rights of other customers.</li>
              <li><strong>Security:</strong> Not attempt to disrupt the Website's security or access any data or content without proper authorization.</li>
            </ol>

            <h4>Third-Party Links</h4>
            <p>
              The Website may contain links to third-party websites, such as fashion blogs or affiliate sites. We do not endorse or control these websites and are not responsible for their content or activities. Your interactions with third-party websites are subject to their terms and policies.
            </p>

            <h4>Limitation of Liability and Disclaimer of Warranties</h4>
            <p>
              While we strive to provide accurate and reliable information about our clothing products, ElegantEdge cannot guarantee the accuracy, completeness, or reliability of any content on our website. By using our services, you agree that ElegantEdge shall not be liable for any indirect, consequential, incidental, special, or punitive damages, or for any loss of profits or revenues, whether incurred directly or indirectly, arising out of your use of the website or any content therein. Furthermore, ElegantEdge disclaims all warranties, express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
            </p>

            <h4>Rules of Conduct</h4>
            <ul>
              <li>Treat other customers with respect and refrain from engaging in any conduct that may harm, harass, or violate their rights.</li>
              <li>Do not attempt to disrupt the website's security or access any data or content without proper authorization.</li>
              <li>Adhere to all applicable local, state, and federal laws and regulations related to online retail and consumer protection.</li>
            </ul>

            <h4>User Restrictions</h4>
            <ul>
              <li>You must be at least 18 years old or have the consent of a parent or guardian to use our services.</li>
              <li>You may not use our website for any illegal or unauthorized purpose, including fraudulent purchases.</li>
              <li>You may not attempt to gain unauthorized access to any portion of our website or any systems or networks connected to our website.</li>
              <li>You may not use our website to transmit any viruses, worms, or malicious code that could harm our systems or other users.</li>
            </ul>

            <h4>Contact Info</h4>
            <p>
              For any inquiries or concerns regarding our Terms of Use, you can contact our customer support team at <a href="mailto:elegantedge62@gmail.com">elegantedge62@gmail.com</a> or <a href="tel:+918107289227">+91-8107289227</a>.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsOfUse;
