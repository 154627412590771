export const Prices = [
  {
    _id: 0,
    name: "Rs 0 to 199",
    array: [0, 199],
  },
  {
    _id: 1,
    name: "Rs 200 to 399",
    array: [200, 399],
  },
  {
    _id: 2,
    name: "Rs 400 to 599",
    array: [400, 599],
  },
  {
    _id: 3,
    name: "Rs 600 to 799",
    array: [600, 799],
  },
  {
    _id: 4,
    name: "Rs 800 to 999",
    array: [800, 999],
  },
  {
    _id: 4,
    name: "Rs 1000 or more",
    array: [1000, 99999999999999],
  },
];
