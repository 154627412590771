import React from 'react';
import Layout from '../../components/Layout/Layout';

const ReturnPolicy = () => {
  return (
    <Layout title={"Return and Refund Policy | ElegantEdge"}>
      <div className="container mt-5">
        <div className="">
          <div className="bg-primary text-white text-center p-4">
            <h2>Return & Refund Policy</h2>
          </div>
          <div className="card-body">
            <p>
              This Return & Refund Policy outlines the terms and conditions for returns and refunds when you purchase clothing products from ElegantEdge. By engaging in transactions with us, you agree to adhere to the terms specified in this policy.
            </p>

            <h4>Eligibility for Returns</h4>
            <p>You may be eligible for a return and refund under the following conditions:</p>
            <ol>
              <li><strong>Damaged or Defective Products:</strong> If you receive a clothing item that is damaged or defective, please contact us within 14 days of receiving the item. We will arrange for a return and provide a full refund or replacement, as applicable.</li>
              <li><strong>Incorrect Product:</strong> If you receive a clothing item that does not match your order, please notify us within 14 days of receiving the item. We will facilitate a return and provide the correct item or a refund, as per your preference.</li>
              <li><strong>Unopened and Unworn Products:</strong> For non-defective and non-damaged clothing items, we accept returns of unopened and unworn products within 14 days of purchase. You will be responsible for return shipping costs, and a restocking fee may apply.</li>
            </ol>

            <h4>Refund Process</h4>
            <p>Refunds will be processed in the following manner:</p>
            <ol>
              <li>For eligible returns, refunds will be issued to the original payment method used during the purchase.</li>
              <li>Refunds will be processed within 2 business days of receiving the returned item and completing the inspection.</li>
            </ol>

            <h4>Exceptions</h4>
            <p>The following items are non-returnable and non-refundable:</p>
            <ul>
              <li><strong>Personalized or Customized Products:</strong> Clothing items that have been personalized or customized to your specifications cannot be returned or refunded unless they are damaged or defective.</li>
              <li><strong>Underwear and Swimwear:</strong> For hygiene reasons, these items cannot be returned or refunded unless they are damaged or defective.</li>
            </ul>

            <h4>Initiate Return Process</h4>
            <p>To initiate a return, customers can follow these steps:</p>
            <ol>
              <li><strong>Send an Email with Product Details and Image Attached:</strong>
                <ul>
                  <li>Compose an email to our returns department.</li>
                  <li>Include the product details such as name, purchase date, and reason for return.</li>
                  <li>Attach an image of the product clearly showing any defects or issues.</li>
                  <li>Send the email to the designated return email address provided on our website.</li>
                </ul>
              </li>
              <li><strong>Call Us:</strong>
                <ul>
                  <li>Contact our customer support team via phone.</li>
                  <li>Provide the necessary details about the clothing item and the reason for the return.</li>
                  <li>Follow the instructions provided by the customer support representative for further assistance in initiating the return process.</li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReturnPolicy;
