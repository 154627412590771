import React from 'react';
import Layout from '../../components/Layout/Layout';

function App() {
  return (
    <Layout title={"About Us "}>
    <div className="container mt-5">
      <header className="bg-primary text-white text-center p-4">
        <h1>Welcome to ElegantEdge – Where Fashion Meets Convenience!</h1>
        <p className="lead">
          At ElegantEdge, we believe that style and convenience should go hand in hand. Our journey began with a simple vision:
          to provide fashion enthusiasts with a seamless and enjoyable shopping experience from the comfort of their homes.
        </p>
      </header>
      <main className="container my-5">
        <section className="mb-5">
          <h2 className="text-primary">What Sets Us Apart</h2>
          <ul className="list-unstyled">
            <li className="mb-3">
              <strong>Quality Assurance:</strong> We understand the importance of quality when it comes to clothing.
              That's why we carefully select our suppliers and rigorously inspect each item before it reaches your doorstep.
              Your satisfaction is our top priority.
            </li>
            <li className="mb-3">
              <strong>Trendy Selection:</strong> Our team of fashion enthusiasts keeps a close eye on the latest trends,
              ensuring that our catalog is always up-to-date with the hottest styles. We want you to look and feel your best every day.
            </li>
            <li className="mb-3">
              <strong>User-Friendly Shopping:</strong> We've designed our website with you in mind. It's easy to navigate,
              search for products, and make secure purchases. Your shopping experience should be as enjoyable as wearing our clothes.
            </li>
            <li className="mb-3">
              <strong>Customer Support:</strong> Have a question or need assistance? Our friendly and knowledgeable
              customer support team is here to help. We're just a click or a call away, ready to assist you with any inquiries or concerns.
            </li>
            <li className="mb-3">
              <strong>Fast and Reliable Shipping:</strong> We know waiting for your new clothes can be exciting.
              That's why we work tirelessly to ensure your orders are processed quickly and delivered to you in the shortest possible time.
            </li>
          </ul>
        </section>
        <section>
          <h2 className="text-primary">Our Commitment</h2>
          <p>
            At ElegantEdge, we're not just selling clothes; we're offering you the opportunity to express yourself, boost your confidence,
            and embrace your unique style. We believe that everyone deserves to look and feel amazing, no matter their budget or location.
          </p>
          <p>
            Join us on this fashion-forward journey, and let's make every day a stylish one together. Thank you for choosing ElegantEdge as your fashion destination.
          </p>
          <p>
            If you have any questions, suggestions, or feedback, please don't hesitate to get in touch. We love hearing from our valued customers.
          </p>
          <p>Happy shopping!</p>
          <p><strong>ElegantEdge Team</strong></p>
        </section>
      </main>
    </div>
    </Layout>
  );
}

export default App;
